"use client";
import { useRouter, useSearchParams } from "next/navigation";
import type { UserCredential } from "firebase/auth";
import { auth as firebaseAuth } from "@/config/firebase";
import {
  saveUserInServer,
  removeAuthCookies,
  setAuthServerToken,
} from "@/auth/authServerActions";
import Google from "@/assets/google.png";
import Facebook from "@/assets/facebook.png";
import {
  FacebookAuthProvider,
  getAuth,
  GoogleAuthProvider,
  OAuthProvider,
  onAuthStateChanged,
  signInWithCustomToken,
  signInWithPopup,
  signOut,
} from "firebase/auth";
import { LogoSelector } from "../../../storybook/components/shared/LogoSelector/LogoSelector";
import { SubmitHandler, useForm } from "react-hook-form";
import { Button, FullLoader, Title } from "@umahealth/occipital";
import { Input } from "@umahealth/occipital/client";
import { ImageButton, LoginPage } from "@/storybook/components/shared";
import { useEffect, useState } from "react";
import { useSignInWithEmailAndPassword } from "../ForgotCredentials/ForgotPassword/ForgotPassword";
import { useUpdateTokens } from "./useUpdateTokens";
import { errorHandler } from "@/config/stackdriver";

export interface ISignInWithEmailAndPassword {
  email: string;
  password: string;
}


export default function LoginView() {
  const router = useRouter();
  const params = useSearchParams();
  const [hasLogged, setHasLogged] = useState(false);
  const redirect = params?.get("redirect");
  const customToken = params?.get("customToken");
  const verifyCache = params?.get("verifyCache");
  const signIn = useSignInWithEmailAndPassword();
  const {
    register,
    formState: { errors },
    handleSubmit,
    setError,
  } = useForm<ISignInWithEmailAndPassword>();
  const auth = getAuth()

  const updateTokens = useUpdateTokens({
		options: {
			onSuccess: () => {
				router.push( redirect ?? '/')
			},
			onError: async() => {
				localStorage.clear()
				removeAuthCookies()
				signOut(auth)
				window.location.replace('/login')
			}
		}
	})

  async function loginWithCustomToken(customToken: string) {
    const credential = await signInWithCustomToken(firebaseAuth, customToken);
    const idTokenResult = await credential.user.getIdTokenResult();

    if (idTokenResult.token) {
      await saveUserInServer(idTokenResult);
      await setAuthServerToken(idTokenResult.token, idTokenResult.expirationTime)
    }
    if(typeof window !== 'undefined'){
      window.location.replace(redirect ?? "/");
    }
  }

  useEffect(() => {
      if (verifyCache === 'true'){
        const authStateChange = onAuthStateChanged(auth, (user) => {
          if (user) {
            updateTokens.mutate(user)
          } else {
            router.push('/login')
          }
        })
        return () => authStateChange()
      }
    }, [auth, verifyCache])

  useEffect(() => {
    if (customToken) {
      loginWithCustomToken(customToken);
    }
  }, [auth, customToken]);

  async function handleLogin(name: "Google" | "Facebook" | "Microsoft") {
    const provider = {
      Google: new GoogleAuthProvider(),
      Facebook: new FacebookAuthProvider(),
      Microsoft: new OAuthProvider("microsoft.com"),
    };
    try {
      setHasLogged(true);
      let credential: UserCredential;
      if (customToken) {
        credential = await signInWithCustomToken(firebaseAuth, customToken);
      } else {
        credential = await signInWithPopup(firebaseAuth, provider[name]);
      }

      const idTokenResult = await credential.user.getIdTokenResult();

      if (idTokenResult.token) {
        await saveUserInServer(idTokenResult);
        await setAuthServerToken(idTokenResult.token, idTokenResult.expirationTime)
        router.push( "/");
      }
      if(typeof window !== 'undefined') {
        window.location.replace(redirect ?? "/");
      }
    } catch (err) {
      setHasLogged(false)
      console.error(err)
      errorHandler?.report(err as Error)
    }
  }

  const handleSignInWithEmail: SubmitHandler<ISignInWithEmailAndPassword> = async (
    formData
  ) => {
    signIn.mutate(
      {
        email: formData.email,
        password: formData.password,
      },
      {
        onSuccess: async (credentials) => {
          const idTokenResult = await credentials.user.getIdTokenResult();

            if (idTokenResult.token) {
              await saveUserInServer(idTokenResult);
              await setAuthServerToken(idTokenResult.token, idTokenResult.expirationTime)
              router.push( "/home");
            }

        },
        onError: () => {
          setError("password", {
            type: "custom",
            message: "Email o contraseña no válida",
          });
        },
      }
    );
  };

  if (hasLogged === true) {
    return (
      <div>
        <FullLoader />
      </div>
    );
  }

  if (verifyCache || updateTokens.isSuccess || updateTokens.isPending) {
		return (
			<div className='h-full grid place-content-center justify-center h gap-4'>
				<FullLoader text='Actualizando sesión...'/>
			</div>
		)
	}

  return (
    <LoginPage className="overflow-y-auto">
          <LogoSelector className="w-64 h-64 self-center"  />
          <ImageButton
            className="mb-4"
            action={() => {
              handleLogin("Google");
            }}
            name="Google"
            logo={Google}
          />
          <ImageButton
            className="mb-4"
            action={() => {
              handleLogin("Facebook");
            }}
            name="Facebook"
            logo={Facebook}
          />
          <form
            className="w-full"
            onSubmit={handleSubmit(handleSignInWithEmail)}
          >
            <Title
              className="mb-4 text-center"
              hierarchy="h2"
              size="text-m"
              weight="font-semibold"
            >
              o ingresa con un mail
            </Title>
            <Input
              classNameContainer="mb-4"
              inputMode="email"
              type="email"
              autoComplete="email"
              label="Tu email"
              placeholder='ejemplo@hotmail.com'
              error={errors.email?.message}
              {...register("email")}
            />
            <Input
              classNameContainer="mb-4"
              label="Contraseña"
              type="password"
              inputMode="text"
              placeholder=''
              error={errors.password?.message}
              {...register("password")}
            />
            <Button className="mb-4" variant="filled" type="submit" size="full" loading={signIn.isPending}>
              Continuar
            </Button>
          </form>
          <div className="pb-2 w-full">
            <Button
              className="mb-4"
              variant="outlined"
              type="button"
              size="full"
              action={() => router.push("/signup?step=0")}
            >
              {" "}
              Crear nueva cuenta{" "}
            </Button>
          </div>
          <Button
            variant="text"
            type="button"
            size="full"
            action={() => router.push("/forgot")}
          >
            Olvidé mi contraseña
          </Button>
    </LoginPage>
  );
}
