import versionNumber from './version.json'
import StackdriverErrorReporter from 'stackdriver-errors-js'

let stackDriver: null | StackdriverErrorReporter = null
// https://github.com/GoogleCloudPlatform/stackdriver-errors-js -> Docu
try {
	stackDriver = new StackdriverErrorReporter()
	stackDriver.start({
		key: `${process.env.NEXT_PUBLIC_FIREBASE_API_KEY}`,
		projectId: `${process.env.NEXT_PUBLIC_FIREBASE_PROJECT_ID}`,
		service: `Doctor-app-${process.env.NEXT_PUBLIC_COUNTRY}`,
		version: versionNumber.doctor,
		context: {
			user: ''
		}
		// You can set the user later using setUser()
	})
} catch (err) {
	console.error(err)
}

export const errorHandler = stackDriver